import { useCallback } from 'react';

import { useLocalStorage } from '~/src/common/hooks/use-local-storage';
import { uniq } from '~/src/common/utils/array';
import { isArrayOfString } from '~/src/common/utils/guards';
import { usePostAuthMeQuery } from '~/src/screens/App/queries/hooks';

import { useMyProductsGetProductSkus } from './use-my-products-get-product-skus';

/**
 * Ce hook indique l'état de la pastille de notification pour "Mes produits", elle est visible si :
 * - L'utilisateur est identifié et posséde au moins un produit dans sa liste
 * - L'utilisateur n'a jamais vu la page "Mes produits" en ayant la pastille active
 *
 * La fonction `disableNotification` permet de masquer la pastille, mais uniquement si
 * elle est visible pour l'utilisateur en cours. C'est à dire pour un utilisateur qui
 * posséde des produits dans sa liste et qui n'a pas encore vu la page "Mes produits".
 */
export const useMyProductsNotification = () => {
  const userID = usePostAuthMeQuery().data?.id;
  const productSKUs = useMyProductsGetProductSkus().data;
  const [userIDs, setUserIDs] = useLocalStorage('keplr.my-products.seen-by', isArrayOfString);

  // Conditions d'affichage de la notification
  const isLoggedIn = userID != null;
  const hasProducts = productSKUs != null && productSKUs.length > 0;
  const hasSeenPage = userID != null && userIDs != null && userIDs.includes(userID);
  const isMyProductNotificationShown = isLoggedIn && hasProducts && !hasSeenPage;

  // Pour marquer la notification comme vue
  const removeMyProductsNotification = useCallback(() => {
    if (!isMyProductNotificationShown) return;
    setUserIDs(uniq([...(userIDs ?? []), userID]));
  }, [isMyProductNotificationShown, setUserIDs, userID, userIDs]);

  return [isMyProductNotificationShown, removeMyProductsNotification] as const;
};
