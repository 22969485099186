import envConstants from '~/src/env-constants';

import components from './components';

export const { BUILDER_API_KEY } = envConstants;

export const BUILDER_COMPONENTS = components;

export const PageModels = [
  'brand', // → /entreprise/[slug]
  'company', // → /nous/[slug]
  'info', // → /info/[slug]
  'landing', // → /offre/[slug]
  'root', // → /[slug]
] as const;
