import { createInputs } from '../../utils';

export default createInputs([
  {
    name: 'categoryID',
    type: 'string',
    required: true,
    friendlyName: 'ID de la catégorie catman',
  },
  {
    name: 'simulateCatalogPadding',
    type: 'boolean',
    friendlyName: "Cocher si le modèle de page utilisé n'a pas de marges",
  },
]);
