import { noop } from './function';

/**
 * Ponyfill pour `Promise.withResolvers()` qui est une API assez récente, pas encore supportée par tous les navigateurs.
 * - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/withResolvers
 * - https://caniuse.com/mdn-javascript_builtins_promise_withresolvers
 */
export const withResolvers = <T>() => {
  let resolve: (value: T) => void = noop;
  let reject: (reason?: unknown) => void = noop;
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return { promise, resolve, reject };
};
