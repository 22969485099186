import styled from 'styled-components';

import AppsflyerSmartBanner from './AppsflyerSmartBanner';
import Didomi from './Didomi';
import GoogleTagManager from './GoogleTagManager';
import Trustpilot from './Trustpilot';
import Zendesk from './Zendesk';

const NoScript = styled.noscript`
  display: block;
  padding: 8px 16px;
  text-align: center;
  background-color: #f0dc23;
`;

const BodyScripts = () => {
  return (
    <>
      <NoScript>JavaScript est nécessaire au bon fonctionnement du site web.</NoScript>
      <Didomi />
      <GoogleTagManager />
      <AppsflyerSmartBanner />
      <Zendesk />
      <Trustpilot />
    </>
  );
};

export default BodyScripts;
