/* eslint-disable @typescript-eslint/restrict-template-expressions */

import I18n from '~/src/common/services/I18n';
import { CartProduct } from '~/src/common/typings/cart';
import { HomeProduct, ItemDefinition, ProductDetailed } from '~/src/common/typings/product';
import { round } from '~/src/common/utils/number';
import { priceFormatter, unitFormatters } from '~/src/common/utils/prices-formatters';
import { ProductPromoWithBulkDiscount } from '~/src/common/utils/product';
import {
  GetCart200DeliveryDeliveryPricesItem,
  GetCart200ProductsItemPromoConditionsType,
  Home200ItemsItemProductsItemOneOfPromoConditionsType,
} from '~/src/queries/api-ecom/generated/api-ecom.schemas';

/**
 * @param price Price in cents
 * @return string Formatte le prix en centimes suivant les options de la locale
 */
export const formatPrice = (priceInCents: number) => {
  const price = priceInCents / 100;

  return priceFormatter.format(price);
};

/**
 * @param price Price in cents
 * @return Object Le détails des éléments principaux du prix préformattés ainsi que le découpage initiale du prix au format Intl.NumberFormatPart[]
 */
export const getPriceParts = (priceInCents: number) => {
  const parts = priceFormatter.formatToParts(priceInCents / 100);

  const integer = parts
    .filter(p => p.type === 'integer')
    .map(p => p.value)
    .join('');
  const fraction = parts
    .filter(p => p.type === 'fraction')
    .map(p => p.value)
    .join('');
  const currency = parts
    .filter(p => p.type === 'currency')
    .map(p => p.value)
    .join('');

  const decimal = parts.find(p => p.type === 'decimal')?.value;

  return {
    integer,
    fraction,
    currency,
    decimal,
    parts,
  };
};

export const toLocalizedWeightUnit = ({
  weight,
  weightUnit = 'kg',
}: {
  weight?: number;
  weightUnit?: 'kg' | 'l';
}) => {
  if (weight == null) return '';

  const isMilli = weight < 1;
  const formatter = isMilli
    ? unitFormatters[weightUnit].milliUnit
    : unitFormatters[weightUnit].unit;
  const value = isMilli ? weight * 1000 : weight;

  return formatter.format(value);
};

type GranularityPriceOptions = {
  displayWeight?: boolean;
  packSize?: number;
};

export const getPriceGranularity = (
  itemDefinition: ItemDefinition,
  options?: GranularityPriceOptions,
) => {
  let priceGranularity = '';
  const { displayWeight, packSize } = options || {};

  const isTerminologyOverride = Boolean(itemDefinition?.terminologyOverride);

  switch (itemDefinition.type) {
    // Par filet (1kg)
    case 'pieceWeight':
      priceGranularity = `${
        isTerminologyOverride
          ? itemDefinition.terminologyOverride
          : itemDefinition.terminology.singular
      }${
        displayWeight && itemDefinition?.weight?.value
          ? ` (${toLocalizedWeightUnit({
              weight: itemDefinition.weight.value * (packSize || 1),
              weightUnit: itemDefinition.weight.unit,
            })})`
          : ''
      }`;

      break;

    // 'Par 500g' ou '3 pommes (500g)'
    case 'arbitraryQuantity': {
      const localizedWeight = toLocalizedWeightUnit({
        weight: itemDefinition.weight?.value,
        weightUnit: itemDefinition.weight?.unit,
      });

      priceGranularity = `${
        isTerminologyOverride
          ? `${localizedWeight} (${itemDefinition.terminologyOverride})`
          : localizedWeight
      }`;
      break;
    }

    // Par botte
    case 'piece':

    // eslint-disable-next-line no-fallthrough
    default:
      priceGranularity = `${
        isTerminologyOverride
          ? itemDefinition.terminologyOverride
          : itemDefinition.terminology?.singular
      }`;

      break;
  }

  return priceGranularity;
};

export const getPricePerWeight = (
  weight: CartProduct['quotation2']['weight'] | ProductDetailed['weightPrice'],
) => {
  if (!weight) {
    return null;
  }

  const amount = formatPrice(weight.unitPrice);

  return `${amount}/${weight.unit}`;
};

export const isPerGranularity = (itemDefinitionType: ItemDefinition['type']) =>
  itemDefinitionType === 'pieceWeight' || itemDefinitionType === 'piece';

export const getPromoPercentage = (
  promo: NonNullable<HomeProduct['promo']>,
  productPrice: number | undefined,
) => {
  const { conditions, itemOriginalPrice } = promo;

  if (!conditions) return undefined;

  const { type, value } = conditions;

  const currentPrice = productPrice || value;

  const isValuePromo = type === 'VALUE';
  const promoAmount = isValuePromo
    ? Math.floor(((itemOriginalPrice - currentPrice) * 100) / itemOriginalPrice)
    : Math.floor(value);

  return promoAmount;
};

export const formatPromoLabel = (
  promo: NonNullable<HomeProduct['promo']>,
  productPrice: number | undefined,
): string => {
  const { conditions } = promo;

  if (!conditions) return '';

  return `-${getPromoPercentage(promo, productPrice)}%`;
};

/**
 * Détermine le montant à ajouter au panier pour bénéficier de la livraison offerte
 * @param deliveryPrices les différentes règles de prix concernant la livraison (s'il y en a)
 * @param totalCartAmount le montant total du panier qui définira le prix de livraison
 * et le montant restant pour avoir la livraison offerte
 * @return le montant nécessaire pour avoir la livraison offerte,
 * renvoie null si la livraison est toujours payante
 */
export const getRestAmountUntilFreeDelivery = (
  deliveryPrices: GetCart200DeliveryDeliveryPricesItem[] | undefined,
  totalCartAmount: number,
): number | null => {
  const firstFreeDeliveryRange = deliveryPrices?.find(
    deliveryPrice => deliveryPrice.shippingAmount === 0,
  );

  if (!firstFreeDeliveryRange) {
    return null;
  }

  const freeDeliveryThreshold = firstFreeDeliveryRange.minCartNetPrice || 0;
  const restAmount = freeDeliveryThreshold - totalCartAmount;
  return restAmount > 0 ? restAmount : 0;
};

/**
 * @param type - Le type de promotion
 * @param value - La valeur de la promotion
 * @returns true si le produit est offert, false sinon en fonction du type et de la valeur de la promotion (100% réduction ou prix à 0€)
 */
const isFreeProduct = (type: GetCart200ProductsItemPromoConditionsType, value: number) => {
  const isPercent = type === 'PERCENT';

  return (!isPercent && value === 0) || (isPercent && value === 100);
};

export const formatBatchDiscountSavingsLabel = (
  promo: {
    nthQuantity: number;
    type: Home200ItemsItemProductsItemOneOfPromoConditionsType;
    value: number;
    discountPerBulk?: number;
    itemOriginalPrice: number;
  },
  quantityInCart: number,
) => {
  const { nthQuantity, type, value, discountPerBulk, itemOriginalPrice } = promo;
  const isPercentage = type === 'PERCENT';
  const isFree = isFreeProduct(type, value);

  let savings;

  if (discountPerBulk)
    savings = round((discountPerBulk * Math.floor(quantityInCart / nthQuantity)) / 100, 2);
  else {
    const nbProductsWithDiscount = Math.floor(quantityInCart / nthQuantity);

    const itemDiscountPrice = isPercentage
      ? itemOriginalPrice - (itemOriginalPrice * (100 - value)) / 100
      : itemOriginalPrice - value;

    savings = ((isFree ? itemOriginalPrice : itemDiscountPrice) * nbProductsWithDiscount) / 100;
  }

  return I18n.t('price.batch-discount-label.savings', {
    savings: savings.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' }),
    count: savings <= 1 ? 1 : savings, // pour gérer le pluriel
  });
};

/**
 * Retourne le texte à afficher pour la promo lot virtuel sans les économies réalisées
 * @param promo - L'objet contenant les informations sur la promotion
 * @param promo.nthQuantityOffered - Le nombre d'articles à acheter pour bénéficier de la promo
 * @param promo.type - Le type de promotion
 * @param promo.value - La valeur de la promotion
 * @returns le texte à afficher pour la promo "lot virtuel" sans les économies réalisées. Ex: "Le 2ème à -50%", "Le 2ème offert", "Le 2ème à 1€"
 */
export const formatBatchDiscountNthText = (promo: {
  nthQuantityOffered: number;
  type: Home200ItemsItemProductsItemOneOfPromoConditionsType;
  value: number;
  discountPerBulk?: number;
}) => {
  const { nthQuantityOffered, type, value, discountPerBulk } = promo;

  if (isFreeProduct(type, value))
    return I18n.t('price.batch-discount-label.free', { nthQuantity: nthQuantityOffered });

  if (discountPerBulk != null && type === 'VALUE')
    return I18n.t('price.batch-discount-label.discount-value', {
      nthQuantity: nthQuantityOffered,
      discount: formatPrice(discountPerBulk),
    });

  const discount = type === 'PERCENT' ? value : formatPrice(value);

  return I18n.t(`price.batch-discount-label.${type.toLowerCase()}`, {
    nthQuantity: nthQuantityOffered,
    discount,
  });
};

export const formatBatchDiscountLabel = (promo: ProductPromoWithBulkDiscount, cartQuantity = 0) => {
  const { conditions, itemOriginalPrice, discountPerBulk } = promo;

  if (!conditions) return '';

  const { type, value, nthQuantity } = conditions || {};

  if (!nthQuantity) return '';

  // promo lot virtuel active : 2 achetés 1 offert et au moins 2 produits dans le panier
  if (nthQuantity <= cartQuantity) {
    return formatBatchDiscountSavingsLabel(
      { nthQuantity, type, value, discountPerBulk, itemOriginalPrice },
      cartQuantity,
    );
  }

  return formatBatchDiscountNthText({
    type,
    value,
    discountPerBulk,
    nthQuantityOffered: nthQuantity,
  });
};
