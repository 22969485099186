import constants from '~/src/env-constants';

const TrustscoreWidget = () => (
  <div
    className="trustpilot-widget"
    data-locale="fr-FR"
    data-template-id="53aa8807dec7e10d38f59f32"
    data-businessunit-id={constants.TRUSTPILOT_BUSINESSUNIT_ID}
    data-style-height="120px"
    data-style-width="225px">
    <a
      href="https://fr.trustpilot.com/review/mon-marche.fr"
      target="_blank"
      rel="noopener noreferrer">
      Trustpilot
    </a>
  </div>
);

export default TrustscoreWidget;
