import dynamic from 'next/dynamic';

import { createComponent } from '../../utils';

import inputs from './inputs';

const BuilderID = 'section:category-swiper';
const Component = dynamic(() => import('./Component'));
export default createComponent(BuilderID, Component, inputs, {
  friendlyName: 'Swiper de catégorie',
  description: 'Affiche un slider de produit similaire à ceux visible sur la home',
});
