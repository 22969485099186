import { useFeatureFlag } from '~/src/common/services/FeatureFlag';
import I18n from '~/src/common/services/I18n';

import { AppsBadgesContainer, AppStoreBadge, GooglePlayBadge, SectionTitle } from '../layout';
import TrustscoreWidget from '../TrustscoreWidget';
import { sendFooterNavigationEvent } from '../utils';

const APP_STORE_URL = 'https://apps.apple.com/fr/app/mon-marché-courses-en-ligne/id1477200978';
const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=fr.monmarche.app&pli=1';

const RatingsAndDownloadAppsSection = () => {
  const isTrustpilotEnabled = useFeatureFlag('widgets-trustpilot');

  return (
    <div>
      <SectionTitle>
        {I18n.t(isTrustpilotEnabled ? 'footer.ratings-and-download' : 'footer.download-apps')}
      </SectionTitle>
      <AppsBadgesContainer>
        <a
          href={APP_STORE_URL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            sendFooterNavigationEvent('app store');
          }}>
          <AppStoreBadge />
        </a>
        <a
          href={GOOGLE_PLAY_URL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            sendFooterNavigationEvent('play store');
          }}>
          <GooglePlayBadge />
        </a>
        {isTrustpilotEnabled ? <TrustscoreWidget /> : null}
      </AppsBadgesContainer>
    </div>
  );
};

export default RatingsAndDownloadAppsSection;
