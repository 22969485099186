export default {
  page: {
    title: 'Mes produits',
  },
  header: {
    title: 'Mes Produits',
    callout: {
      title: 'Votre marché à votre goût',
      text: 'Retrouvez vos produits déjà testés en un coup d’oeil',
    },
  },
  error: {
    title: 'Une erreur est survenue lors du chargement de vos produits',
    button: 'Réessayer',
  },
  empty: {
    title: 'Aucun produit',
    text: 'Retrouvez vos produits dès réception de votre prochaine commande !',
    action: 'Passer une commande',
  },
  buttons: {
    ordered: 'Déjà commandé',
    delete: 'Supprimer',
  },
  dialogs: {
    cardinfo: {
      title: 'Vous avez déjà commandé ce produit.',
      text: 'Gagnez du temps et retrouvez tous vos produits déjà testés dans le menu “Mes Produits”',
      ok: 'Voir Mes produits',
    },
    delete: {
      title: 'Voulez-vous vraiment supprimer ce produit ?',
      text: 'Si vous ne souhaitez plus le voir apparaître dans la liste, cliquez sur le bouton ci-dessous.',
      yes: 'Supprimer de ma liste',
      no: 'Annuler',
    },
    onetime: {
      title: 'Tous vos produits préférés au même endroit !',
      text1:
        'Gagnez du temps et retrouvez tous vos produits déjà commandés dans l’onglet “Mes produits”.',
      text2:
        'Nous mettrons à jour votre liste après chacune de vos commandes pour vous faire gagner du temps.',
      ok: "OK, j'ai compris",
    },
  },
};
