import { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Braze from '~/src/common/services/Braze';
import { logger } from '~/src/common/services/Logger';
import { TagManager } from '~/src/common/services/TagManager';
import Tracker from '~/src/common/services/Tracker';
import { isServerSide } from '~/src/common/utils/server';
import { User } from '~/src/queries/types/user';
import { addCountryCode } from '~/src/screens/AccountManagementModal/utils';
import { QUERY_KEYS } from '~/src/screens/App/queries/queries';

export const useAnalyticsInitializer = () => {
  const queryClient = useQueryClient();
  const featureFlags = useFlags();

  const [experimentsSetup, setExperimentsSetup] = useState(false);

  const user = queryClient.getQueryData<User>(QUERY_KEYS.user);

  useEffect(() => {
    if (!isServerSide()) {
      void Braze.requestPushPermission();
    }
  }, []);

  useEffect(() => {
    if (user?.id) {
      void Braze.changeUser(user.id);
      Tracker.setUserId(user.id);

      TagManager.setDataLayer({
        email: user.email.trim().toLowerCase(),
        userId: user.id,
        phone: addCountryCode(user.phone),
        new_customer: user.newCustomer,
      });
      logger.setUser?.({ id: user.id, email: user.email });
    } else {
      TagManager.setDataLayer({
        new_customer: true,
      });

      // On ne deset pas le user de Braze
      Tracker.setUserId(undefined);
      TagManager.setDataLayer({ email: '', userId: '', phone: '' });
      logger.setUser?.(null);
    }
  }, [user]);

  useEffect(() => {
    const experiments = Object.entries(featureFlags).map(([k, value]) => {
      return `${k}=${JSON.stringify(value)}`;
    });

    Tracker.setUserProperties({ experiments });

    setExperimentsSetup(true);
  }, [featureFlags]);

  return { experimentsSetup };
};
