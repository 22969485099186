import { useGetAccountProductsSku } from '~/src/queries/api-ecom/generated/api-ecom';
import { usePostAuthMeQuery } from '~/src/screens/App/queries/hooks';

export const useMyProductsGetProductSkus = () => {
  const user = usePostAuthMeQuery().data;
  return useGetAccountProductsSku({
    query: {
      enabled: user != null,
      staleTime: 30 * 60 * 1000, // Refresh toutes les 30 minutes
    },
  });
};
