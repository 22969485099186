import Script from 'next/script';

const Trustpilot = () => (
  <Script
    id="trustpilot"
    strategy="afterInteractive"
    src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
    // Permet d'activer le MutationObserver de Trustpilot afin de réagir et d'initialiser
    // automatiquement les widgets intégrés dynamiquement, par exemple sur la fiche produit
    data-dynamic
  />
);

export default Trustpilot;
